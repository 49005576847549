import Title from "components/Title";

export default (props) => {
  return (
    <div {...props}>
      <Title>Om kalkulatoren</Title>
      <p>
        Kalkulatoren er fortsatt i beta-stadiet, så rare resulater kan
        forekomme. Dersom du finner noe underlig, er det supert om du tar
        kontakt på{" "}
        <a href="mailto:admin@mhtech.no?subject=Carculator">admin@mhtech.no</a>{" "}
        med skjermbilde.
      </p>
      <p>
        Denne kalkulatoren er utviklet for å simulere en mulig innfasing og
        utfasing av kjøretøytyper etter drivstofftype for kommunene i
        Østfoldregionen fram til 2030. Det er mulig å simulere virkningen av
        ulike virkemidler og tiltak som kan gjennomføres for å oppnå mest mulig
        fossilfri transport i kommunene fram til 2030. Beregningen gir
        resultater for nybilsalg, bilbestand og klimautslipp år for år.
      </p>
      <p>
        En forklaring på hva som ligger i valgmulighetene (av og på-knappene)
        finner du <a href="/static/media/tiltak.pdf">her</a>.
        <br />
        Et metodenotat for modellen finner du{" "}
        <a href="/static/media/metodenotat.pdf">her</a>.
      </p>
    </div>
  );
};
