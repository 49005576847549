import hero from "images/hero.png";
import err from "images/down for maitenens.PNG";

export default () => {
  return (
    <>
      <div className="d-flex w-100">
        <img
          src={hero}
          alt="Carculator MHTech Flowchange"
          className="mx-auto"
          style={{
            maxWidth: "75%",
            width: 400,
            height: "100%",
            objectFit: "scale-down",
          }}
        />
      </div>
      <hr />
    </>
  );
};
