import React, { useEffect, useState } from "react";
import Title from "components/Title";
import { Button, ButtonGroup, Col, Row, Table } from "react-bootstrap";
import _ from "lodash";
import constants from "data/constants";

import { ResponsiveLine } from "@nivo/line";
// Gives depreciation warning, but fixes are hopefully on the way:
// https://github.com/plouc/nivo/issues/884

// Fuel/group IDs to use from API
const stdIds = {
  personbiler: ["Bensin", "Diesel", "El", "Hydrogen"],
  varebiler: ["Fossile", "El", "Hydrogen"],
  letteLastebiler: ["Fossile", "El", "Gass"],
  tyngreLastebiler: ["Fossile", "El", "Gass", "Hydrogen"],
  alle: ["Fossile", "El", "Gass", "Hydrogen"],
};

const legendColors = {
  El: "#23CE6B",
  Bensin: "#F2A359",
  Fossile: "#566363",
  Diesel: "#F06449",
  Hydrogen: "#5C80BC",
  Gass: "#6CC7F6",
};

const getStdLegend = (vehicle) => {
  let legend = {};

  if (!stdIds[vehicle]) {
    return legend;
  }

  stdIds[vehicle].forEach((key) => {
    legend[key] = legendColors[key];
  });

  return legend;
};

const getStdData = (input, vehicle, dataKey) => {
  // Map frontend logic keys to API keys (vehicles)
  const vehicleKeys = {
    personbiler: "Personbiler",
    varebiler: "Varebiler",
    letteLastebiler: "Lettere lastebiler",
    tyngreLastebiler: "Tyngre lastebiler",
  };

  if (Object.keys(vehicleKeys).includes(vehicle)) {
    let output = [];
    const key = vehicleKeys[vehicle];
    const category = input[key][dataKey];
    const categoryKeys = Object.keys(category);
    const years = input[key]["Year"];

    categoryKeys.forEach((categoryKey) => {
      let data = [];
      let yOnly = [];
      category[categoryKey].forEach((yearCount, yearCountIndex) => {
        data.push({ x: Number(years[yearCountIndex]), y: Number(yearCount) });
        yOnly.push(Number(yearCount));
      });
      if (
        Object.keys(stdIds).includes(vehicle) &&
        stdIds[vehicle].includes(categoryKey)
        // && !isZeros(yOnly)
      ) {
        output.push({
          id: categoryKey,
          color: legendColors[categoryKey],
          data,
        });
      }
    });

    if (output.length === 0) {
      let data = [];

      years.forEach((year) => {
        data.push({ x: Number(year) });
      });

      output.push({ id: "none", color: "#000000", data });
    }

    return output;
  } else if (vehicle === "alle") {
    let sumValues = {};

    Object.keys(vehicleKeys).forEach((vehicleKey) => {
      const key = vehicleKeys[vehicleKey];
      const category = input[key][dataKey];
      const categoryKeys = Object.keys(category);
      const years = input[key]["Year"];

      const combine = {
        Bensin: "Fossile",
        Diesel: "Fossile",
      };
      const isCombinable = (key) => {
        if (Object.keys(combine).includes(key)) {
          return true;
        }
        return false;
      };

      categoryKeys.forEach((categoryKey) => {
        category[categoryKey].forEach((yearValue, yearValueIndex) => {
          let outputKey = categoryKey;

          if (isCombinable(categoryKey)) {
            outputKey = combine[categoryKey];
          }

          if (!sumValues[outputKey]) {
            sumValues[outputKey] = {};
          }

          if (sumValues[outputKey][years[yearValueIndex]]) {
            sumValues[outputKey][years[yearValueIndex]] += yearValue;
          } else {
            sumValues[outputKey][years[yearValueIndex]] = yearValue;
          }
        });
      });
    });

    let output = [];

    Object.keys(sumValues).forEach((categoryKey, categoryIndex) => {
      let data = [];
      Object.keys(sumValues[categoryKey]).forEach((year) => {
        data.push({ x: Number(year), y: Number(sumValues[categoryKey][year]) });
      });
      output.push({
        id: Object.keys(sumValues)[categoryIndex],
        color: legendColors[categoryKey],
        data,
      });
    });

    return output;
  }
  return [];
};

const getCo2Legend = (vehicle) => {
  const personbiler = {
    "Personbiler (Diesel)": "#2f2f2c",
    "Personbiler (Bensin)": "#bf7219",
  };
  const varebiler = {
    "Varebiler (Fossilt)": "#298c6a",
  };
  const letteLastebiler = {
    "Lette Lastebiler (Fossilt)": "#2707c5",
  };
  const tyngreLastebiler = {
    "Tunge Lastebiler (Fossilt)": "#ceca42",
  };

  switch (vehicle) {
    case "personbiler":
      return personbiler;
    case "varebiler":
      return varebiler;
    case "letteLastebiler":
      return letteLastebiler;
    case "tyngreLastebiler":
      return tyngreLastebiler;
    case "alle":
      return {
        ...personbiler,
        ...varebiler,
        ...letteLastebiler,
        ...tyngreLastebiler,
      };
    default:
      break;
  }

  return {};
};

const getCo2Data = (input, vehicle) => {
  const parentKeys = [
    "CO2fromOwnedVeichles", // Typo: should be CO2fromOwnedVehicles
  ];

  const vehicleKeys = {
    personbiler: ["CO2DieselPersonbiler", "CO2BensinPersonbiler"],
    varebiler: ["CO2DieselVarebiler"],
    letteLastebiler: ["CO2DieselLettereLastebiler"],
    tyngreLastebiler: ["CO2DieselTyngreLastebiler"],
    alle: [
      "CO2DieselPersonbiler",
      "CO2BensinPersonbiler",
      "CO2DieselVarebiler",
      "CO2BensinVarebiler",
      "CO2DieselLettereLastebiler",
      "CO2DieselTyngreLastebiler",
    ],
  };

  const vehicleNames = {
    CO2DieselPersonbiler: "Personbiler (Diesel)",
    CO2BensinPersonbiler: "Personbiler (Bensin)",
    CO2DieselVarebiler: "Varebiler (Fossilt)",
    CO2DieselLettereLastebiler: "Lette Lastebiler (Fossilt)",
    CO2DieselTyngreLastebiler: "Tunge Lastebiler (Fossilt)",
  };

  let sumValues = {};

  if (Object.keys(vehicleKeys).includes(vehicle)) {
    parentKeys.forEach((parentKey) => {
      const categoryKeys = vehicleKeys[vehicle];
      const years = input["Personbiler"]["Year"];
      // Years shouldn't be dependent on other data, but will do for now

      categoryKeys.forEach((categoryKey) => {
        const values = input[parentKey][categoryKey];

        if (values) {
          values.forEach((yearValue, yearValueIndex) => {
            yearValue /= 10 ** 6;

            if (!sumValues[categoryKey]) {
              sumValues[categoryKey] = {};
            }

            if (sumValues[categoryKey][years[yearValueIndex]]) {
              sumValues[categoryKey][years[yearValueIndex]] += yearValue;
            } else {
              sumValues[categoryKey][years[yearValueIndex]] = yearValue;
            }
          });
        }
      });
    });

    let output = [];

    Object.keys(sumValues).forEach((categoryKey, categoryIndex) => {
      let data = [];
      Object.keys(sumValues[categoryKey]).forEach((year) => {
        data.push({ x: Number(year), y: Number(sumValues[categoryKey][year]) });
      });
      output.push({
        id: Object.keys(sumValues)[categoryIndex],
        color: getCo2Legend(vehicle)[vehicleNames[categoryKey]],
        data,
      });
    });

    return output;
  }

  return [];
};

export default React.memo(({ data, vehicle, ...props }) => {
  if (!data) {
    return null;
  }

  const getFilteredData = (vehicle) => {
    return [
      {
        title: "Bilbestand",
        unit: "biler",
        data: getStdData(data, vehicle, "Bilbestand"),
        legend: getStdLegend(vehicle),
      },
      {
        title: "Nybilsalg",
        unit: "biler",
        data: getStdData(data, vehicle, "Nybilsalg"),
        legend: getStdLegend(vehicle),
      },
      {
        title: "CO₂",
        unit: "tonn",
        data: getCo2Data(data, vehicle),
        legend: getCo2Legend(vehicle),
      },
    ];
  };

  const dataFiltered = getFilteredData(vehicle);

  const DataTable = ({ year = 2030, ...props }) => {
    const validCombos = {
      personbiler: {
        renewable: {
          label: "Fornybart",
          fuels: ["El", "Hydrogen", "Gass"],
        },
        fossile: {
          label: "Fossilt",
          fuels: ["Bensin", "Diesel", "Fossile"],
        },
      },
      varebiler: {
        renewable: {
          label: "Fornybart",
          fuels: ["El", "Hydrogen", "Gass"],
        },
        fossile: {
          label: "Fossilt",
          fuels: ["Bensin", "Diesel", "Fossile"],
        },
      },
      letteLastebiler: {
        renewable: {
          label: "Fornybart",
          fuels: ["El", "Hydrogen", "Gass"],
        },
        fossile: {
          label: "Fossilt",
          fuels: ["Bensin", "Diesel", "Fossile"],
        },
      },
      tyngreLastebiler: {
        renewable: {
          label: "Fornybart",
          fuels: ["El", "Hydrogen", "Gass"],
        },
        fossile: {
          label: "Fossilt",
          fuels: ["Bensin", "Diesel", "Fossile"],
        },
      },
    };

    return (
      <div {...props}>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Kjøretøy</th>
              {dataFiltered.map((d, index) => (
                <th key={`table-header-${d.title}-${index}`}>
                  {`${d.title}`}
                  <span
                    className="text-muted"
                    style={{ fontWeight: "normal" }}
                  >{` i ${year}${d["unit"] ? ` [${d["unit"]}]` : ``}`}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.keys(constants).map((vehicleKey, vehicleIndex) => {
              if (vehicle === "alle" || vehicle === vehicleKey) {
                const data = getFilteredData(vehicleKey);

                return Object.keys(validCombos[vehicleKey]).map(
                  (comboKey, comboIndex) => {
                    const combo = validCombos[vehicleKey][comboKey];
                    let parentValues = [];

                    combo.fuels.forEach((fuel) => {
                      let values = [];

                      data.forEach((category) => {
                        const correctCombo = (e) => {
                          if (e && e.id && !e.id.includes("CO2")) {
                            return false;
                          }

                          const correctFuel = (e) => {
                            e.id
                              .slice("CO2".length, fuel.length)
                              .toLowerCase()
                              .includes(fuel.toLowerCase());

                            const input = e.id.slice(
                              "CO2".length,
                              "CO2".length + fuel.length
                            );

                            const correct =
                              input.toLowerCase() === fuel.toLowerCase();

                            return correct;
                          };

                          const correctVehicle = (e) => {
                            if (vehicle === "alle") {
                              return true;
                            }

                            const input = e.id.slice(
                              "CO2".length + fuel.length
                            );

                            const correct =
                              input.toLowerCase() === vehicle.toLowerCase();

                            return correct;
                          };

                          const correct = correctFuel(e) && correctVehicle(e);

                          return correct;
                        };

                        const isPopulation = [
                          "bilbestand",
                          "nybilsalg",
                        ].includes(category.title.toLowerCase());

                        const isCo2 =
                          category.title.toLowerCase().slice(0, 2) === "co";

                        const line =
                          category.data[
                            category.data.findIndex(
                              (e) => e.id === fuel || correctCombo(e)
                            )
                          ];

                        const getPopulation = () => {
                          const fuelData =
                            category.data[
                              category.data.findIndex(
                                (e) => e.id.toLowerCase() === fuel.toLowerCase()
                              )
                            ].data;

                          const yearData = Math.floor(
                            fuelData[fuelData.findIndex((e) => e.x === year)].y
                          );

                          return yearData;
                        };

                        const getCo2 = () => {
                          const co2Category =
                            category.data[
                              category.data.findIndex((e) => correctCombo(e))
                            ];
                          const co2Data = co2Category.data;

                          const yearData = Math.floor(
                            co2Data[co2Data.findIndex((e) => e.x === year)].y
                          );

                          return yearData;
                        };

                        if (line) {
                          isPopulation
                            ? values.push(getPopulation())
                            : isCo2 && values.push(getCo2());
                        } else {
                          values.push(0);
                        }
                      });

                      parentValues.push(values);
                    });

                    let finalValues = new Array(parentValues.length).fill(0);

                    parentValues[0].forEach((_, i) => {
                      parentValues.forEach((values, j) => {
                        finalValues[i] += Number(values[i]);
                      });
                    });

                    if (parentValues.every((e) => e === "-")) {
                      return null;
                    }

                    return (
                      <tr
                        key={`table-row-${vehicleKey}-${vehicleIndex}-subdata-${comboIndex}`}
                        hidden={false}
                      >
                        <td>{`${constants[vehicleKey]["label"]} (${combo.label})`}</td>
                        {finalValues.map((value, valueIndex) => {
                          return (
                            <td
                              key={`table-value-${vehicleKey}-${vehicleIndex}-subdata-${comboIndex}-value-${valueIndex}`}
                            >
                              {value}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  }
                );
              }
              return null;
            })}
          </tbody>
        </Table>
      </div>
    );
  };

  const Graphs = () => {
    const Legend = (legend) => {
      legend = legend.data;

      return (
        <div
          className="d-flex flex-wrap justify-content-center"
          style={{ position: "relative", bottom: 5 }}
        >
          {Object.keys(legend).map((legendKey, legendIndex) => {
            return (
              <div
                key={`legend-${legendKey}-${legendIndex}`}
                className={`d-flex align-items-center mx-2`}
              >
                <div
                  style={{
                    backgroundColor: legend[legendKey],
                    width: 9,
                    height: 9,
                    borderRadius: "100%",
                    position: "relative",
                    top: 1,
                  }}
                ></div>
                <small className="ml-1">{legendKey}</small>
              </div>
            );
          })}
        </div>
      );
    };

    const Graph = ({ d, index }) => {
      // Percentage calculations
      // _____________________________________________________
      const [percent, setPercent] = useState(false);
      const [graphData, setGraphData] = useState(d.data);
      let years = [];
      // let vehicles = [];

      d.data.forEach((vehicle) => {
        // vehicles.push(vehicle.id);
        vehicle.data.forEach((pair) => {
          years.push(pair.x);
        });
      });

      useEffect(() => {
        if (percent) {
          // Find totals
          let totals = {};
          years.forEach((year) => {
            let total = 0;

            d.data.forEach((vehicle) => {
              total +=
                vehicle.data[vehicle.data.findIndex((pair) => pair.x === year)]
                  .y;
            });
            totals[year] = total;
          });

          // Fill dPercent with y-values as percentages
          // dPercent = _.cloneDeep(d);

          let dPercent = {
            title: d.title,
            unit: d.unit,
            data: [],
            legend: d.legend,
          };

          d.data.forEach((vehicle, vehicleIndex) => {
            dPercent.data.push({
              id: vehicle.id,
              color: vehicle.color,
              data: [],
            });
            vehicle.data.forEach((pair, pairIndex) => {
              dPercent.data[vehicleIndex].data.push({
                x: pair.x,
                y: totals[pair.x] === 0 ? 0 : pair.y / totals[pair.x],
              });
            });
          });

          setGraphData(dPercent.data);
        } else {
          setGraphData(d.data);
        }
      }, [percent]);
      // _____________________________________________________

      // ! WARNING: ResponsiveLine does not act well in production
      // Always test with build locally before deployment if any changes
      // regarding plots are done.
      const Line = ({
        key,
        format = (y) => `${Math.floor(Number(y) + 0.5)}`,
      }) => {
        return (
          <ResponsiveLine
            key={key}
            // motionStiffness={110}
            // motionDamping={17}
            data={graphData}
            margin={{ top: 20, right: 5, bottom: 35, left: 40 }}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: 0,
              max: "auto",
              reverse: false,
            }}
            yFormat={format}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: "bottom",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: -45,
            }}
            axisLeft={{
              format: format,
              orient: "left",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: -45,
              legendOffset: -45,
              legendPosition: "middle",
            }}
            colors={(e) => e["color"]}
            enablePoints={false}
            curve="monotoneX"
            useMesh={false}
            animate={false}
          />
        );
      };

      return (
        <Col xs="12" md={dataFiltered.length === index + 1 ? 12 : 6} xl="4">
          <div
            className="pb-5"
            style={{ width: "100%", height: 350, maxHeight: "50vh" }}
          >
            <div
              className="d-flex w-100 justify-content-between"
              style={{ position: "relative", top: 10 }}
            >
              <div
                className="d-flex align-items-center w-100"
                style={{ position: "relative", left: 38 }}
              >
                <h6 className="text-center my-0 py-0">{d.title}</h6>
                {/* Big screen */}
                <span
                  className="d-none d-sm-inline"
                  style={{ position: "relative", top: 1 }}
                >
                  <span className="text-muted">/år</span>
                  <span className="ml-1 text-muted">
                    [{percent ? "%" : d["unit"]}]
                  </span>
                </span>
                {/* Small screen */}
                <span
                  className="d-inline d-sm-none"
                  style={{ position: "relative", top: 1 }}
                >
                  <span className="text-muted">/år</span>
                </span>
              </div>
              <ButtonGroup
                className="ml-3"
                size="sm"
                aria-label="Percent/total toggle"
                style={{ zIndex: 1 }}
              >
                <Button
                  variant="light"
                  className="border"
                  onClick={() => setPercent(true)}
                  disabled={percent}
                >
                  <span className="d-none d-sm-inline">Prosent</span>
                  <span className="d-inline d-sm-none">%</span>
                </Button>
                <Button
                  variant="light"
                  className="border"
                  onClick={() => setPercent(false)}
                  disabled={!percent}
                >
                  <span className="d-none d-sm-inline">Antall</span>
                  <span className="d-inline d-sm-none">#</span>
                </Button>
              </ButtonGroup>
            </div>
            {percent ? (
              <Line
                key={`${d.title}-percent`}
                format={(y) => `${Math.floor(Number(y) * 100 + 0.5)}%`}
              />
            ) : (
              <Line key={`${d.title}-count`} />
            )}
          </div>
          <Legend data={d.legend} />
        </Col>
      );
    };

    return (
      dataFiltered &&
      dataFiltered.map((d, index) => (
        <Graph key={`graph-${index}`} d={d} index={index} />
      ))
    );
  };

  return (
    <div {...props}>
      <Title>Resultat</Title>
      <Row className="align-items-start">
        <Col xs="12" lg="6">
          {/* Big screen table */}
          <DataTable
            year={2016}
            className="d-none d-sm-table w-100"
            style={{ fontSize: 14 }}
          />
          {/* Small screen table */}
          <DataTable
            year={2016}
            className="d-table d-sm-none w-100"
            style={{ fontSize: 9.5 }}
          />
        </Col>
        <Col xs="12" lg="6">
          {/* Big screen table */}
          <DataTable
            year={2030}
            className="d-none d-sm-table w-100"
            style={{ fontSize: 14 }}
          />
          {/* Small screen table */}
          <DataTable
            year={2030}
            className="d-table d-sm-none w-100"
            style={{ fontSize: 9.5 }}
          />
        </Col>
        <Graphs />
      </Row>
    </div>
  );
});
