import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";

export default ({ children, header }) => {
  const [show, setShow] = useState(false);

  if (["", null, false].includes(children)) {
    return null;
  }

  return (
    <>
      <Button
        variant="light"
        className="border py-0 px-2 m-0 text-secondary"
        style={{ height: 26 }}
        type="button"
        onClick={() => setShow(true)}
      >
        <b>?</b>
      </Button>
      <Modal
        animation={false}
        show={show}
        centered
        onHide={() => setShow(false)}
      >
        <Modal.Header>
          <Modal.Title>{header}</Modal.Title>
          <Button
            variant="light"
            className="border px-1 py-0 text-muted"
            onClick={() => setShow(false)}
          >
            <b style={{ padding: 2 }}>✕</b>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div style={{ zIndex: 0 }}>{children}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="w-100" onClick={() => setShow(false)}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
