import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  ProgressBar,
  Modal,
  InputGroup,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import municipalities from "data/municipalities";
import Feedback from "components/Feedback";
import Visualization from "components/Visualization";
import preprocess from "functions/preprocess.js";
import useFetch from "use-http";
import useTimer from "hooks/useTimer";
import Dots from "components/Dots";
import ReactJson from "react-json-view";
import constants from "data/constants";
import { capitalizeFirstLetter } from "functions/general.js";
import Info from "./Info";
import PolicyInfo from "./PolicyInfo";
import example from "data/example.json";

const getDefaultPolicies = () => {
  let output = {};

  Object.keys(constants).forEach((vehicleKey) => {
    const vehicle = constants[vehicleKey];
    output[vehicleKey] = {};

    Object.keys(vehicle["policies"]).forEach((policyKey) => {
      const policy = vehicle["policies"][policyKey];
      output[vehicleKey][policyKey] = {};
      output[vehicleKey][policyKey]["checked"] = policy["checked"];
      output[vehicleKey][policyKey]["index"] = 0;
    });
  });

  return output;
};

const getDefaultPercentages = () => {
  let output = {};

  Object.keys(constants).forEach((vehicleKey) => {
    const vehicle = constants[vehicleKey];
    output[vehicleKey] = {};

    Object.keys(vehicle["values"]).forEach((valueKey) => {
      const value = vehicle["values"][valueKey];

      if (typeof value === "number") {
        output[vehicleKey][valueKey] = value;
      } else {
        output[vehicleKey][valueKey] = value["basis"];
      }
    });
  });

  return output;
};

export default (props) => {
  // const [data, setData] = useState();
  const [data, setData] = useState(example);

  const { register, handleSubmit, errors, reset, watch } = useForm();

  const allFields = watch();

  const { get, response, loading, error } = useFetch(
    "https://backendacarculator.mhtech.no"
    //"http://193.69.215.186:8080"
  );

  const [advanced, setAdvanced] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(
    Object.keys(constants)[0]
  );
  const [policies, setPolicies] = useState(getDefaultPolicies());
  const [percentages] = useState(getDefaultPercentages());
  const [previewData, setPreviewData] = useState(false);

  const onSubmit = (values) => {
    handleStart();

    const getPath = (values) =>
      [
        "/prediction",
        values.percentElRenewablePersonbiler,
        values.percentBensinOfFossilePersonbiler,
        values.ntpGoalPersonbiler,
        values.growthRatePersonbiler,
        values.percentElRenewableVarebiler,
        values.percentBensinOfFossileVarebiler,
        values.ntpGoalVarebiler,
        values.growthRateVarebiler,
        values.percentElRenewableLetteLastebiler,
        values.percentBensinOfFossileLetteLastebiler,
        values.ntpGoalLetteLastebiler,
        values.growthRateLetteLastebiler,
        values.percentElRenewableTyngreLastebiler,
        values.percentBensinOfFossileTyngreLastebiler,
        values.ntpGoalTyngreLastebiler,
        values.growthRateTyngreLastebiler,
        values.municipality,
        values.m2025Varebiler,
        values.m2025LetteLastebiler,
        values.m2025TyngreLastebiler,
      ].join("/");

    async function api(path) {
      const result = await get(path);
      if (response.ok) {
        setData(result);
        handlePause();
        handleReset();
      } else {
        handlePause();
        handleReset();
      }
    }

    // Calculate values if policies, otherwise pass variables
    if (true) {
      const path = getPath({
        ...values,
        ...preprocess(policies, values),
      });
      api(path);
    } else {
      const path = getPath({
        ...values,
      });
      api(path);
    }
  };

  const { timer, isActive, handleStart, handlePause, handleReset } = useTimer(
    0
  );

  const fetchTimeEstimate = 30; // Seconds
  let progress = (timer * 100) / fetchTimeEstimate; // Percentage
  progress = progress < 0 ? 0 : progress > 100 ? 100 : progress; // Lock percentage to 0-100

  useEffect(() => {
    if (progress > 100 && isActive) {
      handlePause();
    }
  }, [timer, progress, isActive, handlePause]);

  return (
    <div {...props}>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs="12" sm="6">
            <Form.Group>
              <Form.Label>Kommune</Form.Label>
              <Form.Control
                as="select"
                custom
                ref={register({ required: true })}
                name="municipality"
                isInvalid={errors["municipality"]}
              >
                {municipalities.map((municipality, index) => (
                  <option
                    key={`municipality-${municipality.value}-${index}`}
                    value={municipality.value}
                  >
                    {municipality.label}
                  </option>
                ))}
              </Form.Control>
              <Feedback>
                {errors["municipality"] && `Én kommune må velges`}
              </Feedback>
            </Form.Group>
          </Col>{" "}
          <Col xs="12" sm="6">
            <Form.Group>
              <Form.Label>Kjøretøy</Form.Label>
              <Form.Control
                as="select"
                custom
                ref={register({ required: true })}
                name="vehicle"
                isInvalid={errors["vehicle"]}
                onChange={(e) => {
                  setSelectedVehicle(e.target.value);
                }}
              >
                {Object.keys(constants).map((vehicleKey, index) => (
                  <option
                    key={`vehicle-${vehicleKey}-${index}`}
                    value={vehicleKey}
                  >
                    {constants[vehicleKey]["label"]}
                  </option>
                ))}
                <option value={"alle"}>Alle</option>
              </Form.Control>
              <Feedback>
                {errors["vehicle"] && `Én eller flere typer kjøretøy må velges`}
              </Feedback>
            </Form.Group>
          </Col>
        </Row>

        {["national", "local"].map((scale, scaleIndex) => {
          const split = !(advanced && scaleIndex > 0);

          return (
            <div key={`scale-${scaleIndex}-${scale}`}>
              {split && (
                <>
                  <div className="d-flex justify-content-between">
                    <span>
                      <h5 className="mb-1">
                        {!advanced && scale === "national" && "Nasjonale "}
                        {!advanced && scale === "local" && "Lokale "}
                        {advanced ? "Basispunkter" : "tiltak"}
                      </h5>
                    </span>
                    {/* <Info header={"Test"}>Test</Info> */}
                  </div>
                  <hr className="mt-1 mb-1" />
                </>
              )}

              {Object.keys(constants).map((vehicleKey, vehicleIndex) => {
                const vehicle = constants[vehicleKey];

                if (!split) {
                  return null;
                }

                return (
                  <div
                    key={`vehicle-variables-${vehicleIndex}`}
                    hidden={
                      !(
                        selectedVehicle === "alle" ||
                        vehicleKey === selectedVehicle
                      )
                    }
                    className="mb-4"
                  >
                    <div className="d-flex justify-content-between my-2">
                      <h6>{constants[vehicleKey]["label"]}</h6>
                      <Info header={vehicle.label}>{vehicle.info}</Info>
                    </div>

                    {/* <hr className="mt-1 mb-4" /> */}
                    <Row className="align-items-end" hidden={!advanced}>
                      {Object.keys(percentages[vehicleKey]).map(
                        (percentageKey, percentageIndex) => {
                          if (scaleIndex > 0) {
                            return null;
                          }
                          return (
                            <Col
                              key={`percentage-${vehicleKey}-${percentageKey}-${percentageIndex}`}
                              xs="12"
                              sm="6"
                              lg="3"
                            >
                              <Form.Group>
                                <Form.Label style={{ wordBreak: "break-word" }}>
                                  {
                                    constants[vehicleKey].values[percentageKey]
                                      .label
                                  }
                                </Form.Label>
                                <InputGroup>
                                  <Form.Control
                                    type="number"
                                    name={`${percentageKey}${capitalizeFirstLetter(
                                      vehicleKey
                                    )}`}
                                    defaultValue={
                                      percentages[vehicleKey][percentageKey]
                                    }
                                    isInvalid={
                                      errors[`${vehicleKey}${percentageKey}`]
                                    }
                                    ref={register({
                                      required: true,
                                    })}
                                  />
                                  <InputGroup.Append>
                                    <InputGroup.Text>%</InputGroup.Text>
                                  </InputGroup.Append>
                                </InputGroup>
                              </Form.Group>
                            </Col>
                          );
                        }
                      )}
                    </Row>

                    {!advanced && (
                      <Row>
                        {Object.keys(vehicle["policies"]).map(
                          (policyKey, policyIndex) => {
                            const policy = vehicle["policies"][policyKey];

                            if (scale !== policy.scale) {
                              return null;
                            }

                            const checked =
                              policies[vehicleKey] &&
                              policies[vehicleKey][policyKey]
                                ? policies[vehicleKey][policyKey]["checked"]
                                : false;

                            return (
                              <Col
                                key={`policy-column-${vehicleKey}-${vehicleIndex}-${policyKey}-${policyIndex}`}
                                xs={12}
                                sm={6}
                                lg={4}
                                xl={3}
                                className="mb-3"
                              >
                                <div className="p-2 h-100 d-flex card shadow-sm">
                                  <div className="d-flex w-100">
                                    <Form.Check
                                      key={`policy-check-${vehicleKey}-${vehicleIndex}-${policyKey}-${policyIndex}`}
                                      id={`policy-check-${vehicleKey}-${vehicleIndex}-${policyKey}-${policyIndex}`}
                                      className="ml-1 unselectable w-100"
                                      custom
                                      inline
                                      name={policyKey}
                                      type="switch"
                                      label={policy["label"]}
                                      // checked={checked}
                                      onChange={(e) => {
                                        e.persist();
                                        setPolicies((prevState) => {
                                          prevState[vehicleKey][policyKey][
                                            "checked"
                                          ] = e.target.checked;

                                          return { ...prevState };
                                        });
                                      }}
                                      checked={checked}
                                    />
                                    <PolicyInfo
                                      header={policy.label}
                                      policy={policy}
                                    />
                                  </div>
                                  {checked &&
                                    policy["variables"][
                                      Object.keys(policy["variables"])[0]
                                    ]["values"].length > 1 &&
                                    Object.keys(policy["variables"]).map(
                                      (variableKey, variableIndex) => {
                                        if (variableIndex > 0) {
                                          return null;
                                        }

                                        return (
                                          <div
                                            key={`policy-option-${scale}-${vehicleKey}-${policyKey}-${variableKey}`}
                                            className="d-flex w-100"
                                          >
                                            <Form.Group
                                              className="mb-0 mt-2 w-100"
                                              key={variableIndex}
                                            >
                                              <Form.Control
                                                as="select"
                                                custom
                                                // name="vehicle"
                                                onChange={(e) => {
                                                  e.persist();
                                                  setPolicies((prevState) => {
                                                    prevState[vehicleKey][
                                                      policyKey
                                                    ]["index"] = policy[
                                                      "variables"
                                                    ][variableKey][
                                                      "values"
                                                    ].findIndex(
                                                      (value) =>
                                                        value ===
                                                        Number(e.target.value)
                                                    );
                                                    return { ...prevState };
                                                  });
                                                }}
                                                value={
                                                  policy["variables"][
                                                    variableKey
                                                  ]["values"][
                                                    policies[vehicleKey][
                                                      policyKey
                                                    ]["index"]
                                                  ]
                                                }
                                              >
                                                {policy["variables"][
                                                  variableKey
                                                ]["values"].map(
                                                  (value, index) => {
                                                    return (
                                                      <option
                                                        key={`constant-${vehicleKey}-${policyKey}-${variableKey}-${variableIndex}-${index}`}
                                                        value={value}
                                                      >
                                                        {value > 0 && "Positiv"}
                                                        {value === 0 &&
                                                          "Nøytral"}
                                                        {value < 0 && "Negativ"}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                              </Form.Control>
                                            </Form.Group>
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              </Col>
                            );
                          }
                        )}
                      </Row>
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}

        <Row>
          <Col xs={12} md={4}>
            <Button
              variant="light"
              className="border w-100"
              type="button"
              onClick={() => setAdvanced(!advanced)}
            >
              {advanced ? "Tiltak" : "Avansert"}{" "}
            </Button>
          </Col>
          <Col xs={12} md={4} className="mt-1 mt-md-0">
            {advanced ? (
              <Button
                variant="info"
                className="border w-100"
                type="button"
                onClick={() =>
                  reset({
                    municipality: allFields.municipality,
                    vehicle: allFields.vehicle,
                  })
                }
              >
                Tilbakestill basispunkter
              </Button>
            ) : (
              <Button
                variant="info"
                className="border w-100"
                type="button"
                onClick={() => setPolicies(getDefaultPolicies())}
              >
                Tilbakestill tiltak
              </Button>
            )}
          </Col>
          <Col xs={12} md={4} className="mt-1 mt-md-0">
            <Button className="w-100" type="submit" disabled={loading}>
              Kalkuler
            </Button>
          </Col>
        </Row>

        {loading && (
          <div className="mt-5 w-100 text-center">
            <Dots>Regner ut</Dots>
            <ProgressBar animated now={progress} />
          </div>
        )}
        {!error && timer > fetchTimeEstimate && (
          <div className="mt-5 w-100 text-center text-secondary">
            <p>Dette tar lengre tid enn normalt...</p>
          </div>
        )}
        {error && (
          <div className="mt-5 w-100 text-center text-secondary">
            <p>
              Noe gikk galt. Antagligvis skyldes dette stor pågang, så prøv
              igjen.{" "}
              <span role="img" aria-label="sad-emoji">
                😢
              </span>
            </p>
            <p>
              Prøv igjen eller{" "}
              <a href="http://www.mhtech.no/ContactUs.html">kontakt MHTech</a>.
            </p>
            <div>Feilmelding:</div>
            <div>"{error.message}"</div>
          </div>
        )}

        <Visualization data={data} vehicle={selectedVehicle} className="mb-3" />

        {data && (
          <>
            <Row className="mt-3">
              <Col xs={12} sm={6}>
                <Button
                  variant="light"
                  className="border w-100"
                  type="button"
                  onClick={() => setPreviewData(true)}
                >
                  {`Se rådata`}
                </Button>
              </Col>
              <Col xs={12} sm={6} className="mt-1 mt-sm-0">
                <Button
                  variant="light"
                  className="border w-100"
                  type="button"
                  href={`data:text/json;charset=utf-8,${encodeURIComponent(
                    JSON.stringify(data)
                  )}`}
                  download="data.json"
                >
                  {`Last ned rådata`}
                </Button>
              </Col>
            </Row>
            <Modal
              animation={false}
              show={previewData}
              centered
              onHide={() => setPreviewData(false)}
            >
              <Modal.Body style={{ backgroundColor: "" }}>
                <div
                  className="d-flex justify-content-end"
                  style={{ height: 0 }}
                >
                  <div style={{ zIndex: 1 }}>
                    <Button
                      variant="light"
                      className="border px-1 py-0 text-muted"
                      onClick={() => setPreviewData(false)}
                    >
                      <b style={{ padding: 2 }}>✕</b>
                    </Button>
                  </div>
                </div>
                <div style={{ zIndex: 0 }}>
                  <ReactJson src={data} collapsed={1} />
                </div>
              </Modal.Body>
            </Modal>
          </>
        )}
      </Form>
    </div>
  );
};
