import importedConstants from "../data/constants.js";
import { capitalizeFirstLetter } from "functions/general.js";

/**
 * Returns values to be used in API-call as object literal,
 * given array of vehicles and object literal of policies
 */
function preprocess(
  policiesInput,
  inputValues = null,
  constants = importedConstants
) {
  /**
   * Returns an API-value, given a vehicle and value name
   */
  function getValue(vehicleKey, valueKey) {
    let valueObject = constants[vehicleKey]["values"][valueKey];

    // Return constant if no calculations necessary
    // Calculate if needed
    if (typeof valueObject === "number") {
      return valueObject;
    } else if (!valueObject.calculation) {
      return valueObject.basis;
    } else {
      let basis = valueObject["basis"];

      if (inputValues !== null) {
        basis = Number(
          inputValues[`${valueKey}${capitalizeFirstLetter(vehicleKey)}`]
        );
      }

      let policies = constants[vehicleKey]["policies"];
      let result = basis;

      Object.keys(policies).forEach((policyKey) => {
        if (
          policies[policyKey] &&
          policiesInput[vehicleKey][policyKey]["checked"]
        ) {
          if (valueObject["calculation"] === "percent") {
            if (
              policies[policyKey]["variables"][valueKey] &&
              policies[policyKey]["variables"][valueKey]["values"]
            ) {
              result =
                result +
                basis *
                  (policies[policyKey]["variables"][valueKey]["values"][
                    policiesInput[vehicleKey][policyKey]["index"]
                  ] /
                    100);
            }
          } else if (valueObject["calculation"] === "sum") {
            if (
              policies[policyKey]["variables"][valueKey] &&
              policies[policyKey]["variables"][valueKey]["values"]
            ) {
              result +=
                policies[policyKey]["variables"][valueKey]["values"][
                  policiesInput[vehicleKey][policyKey]["index"]
                ];
            }
          }
        }
      });

      return result;
    }
  }

  let values = {};

  Object.keys(constants).forEach((vehicleKey) => {
    Object.keys(constants[vehicleKey]["values"]).forEach((valueKey) => {
      const value = getValue(vehicleKey, valueKey);
      values[`${valueKey}${capitalizeFirstLetter(vehicleKey)}`] = String(value);
    });
  });

  return values;
}

export default preprocess;
