export default {
  personbiler: {
    info:
      "Det antas som basis at man oppnår ca 80% andel utslippsfrie kjøretøy av nyregistrerte kjøretøy i 2025 med dagens virkemidler, og at elbiler vil være dominerende teknologi av disse.",
    label: "Personbiler",
    policies: {
      moms: {
        label: "MVA og Engangsavgift",
        info:
          "Med dette tiltaket på (grønn bakgrunn på knappen) vil man vurdere effekten på nybilsalg dersom engangsavgift og MVA innføres på fossilfrie personbiler. Dette vil ha en vesentlig negativ effekt, tilsvarende en reduksjon på 40% i andelen utslippsfrie nyregistrerte personbiler i 2025.",
        unit: "",
        checked: false,
        variables: {
          ntpGoal: { values: [-25] },
        },
        scale: "national",
      },
      co2AvgiftDobling: {
        label: "65% økning i CO₂-avgift",
        info:
          "Med dette tiltaket på (grønn bakgrunn på knappen) vil man vurdere effekten av at CO2 avgiften økes som planlagt tilsvarende 65% over perioden. Dette vil ha en positiv effekt på innfasing av utslippsfrie personbiler, tilsvarende ca 6% økning i andelen utslippsfrie nyregistrerte personbiler i 2025.",
        unit: "",
        checked: false,
        variables: {
          ntpGoal: { values: [6] },
        },
        scale: "national",
      },
      teknologi: {
        label: "Teknologi",
        info:
          "Dette er en vurdering av fremtidsoptimisme for utvikling av rekkevidde og pris for fossilfrie biler. I nøytral posisjon forventes en utvikling på linje med den nåværende utviklingen. I negativ posisjon forventes en stagnasjon i utviklingen noe som vil redusere andelen utslippsfrie nyregistrerte personbiler i 2025 med ca 10%. I positiv posisjon forventes en betydelig økt utviklingshastighet (for eksempel dobling av rekkevidde til samme kostnad i løpet av få år) noe som vil øke andelen utslippsfrie nyregistrerte personbiler med ca 10% i 2025.",
        unit: "",
        checked: false,
        variables: {
          ntpGoal: { values: [0, -10, 10] },
        },
        scale: "national",
      },
      bomring: {
        label: "Bomring",
        info:
          "Med dette tiltaket på (grønn bakgrunn på knappen) vil man vurdere effekten på nybilsalg dersom det innføres bomring med differensiert pris for utslippsfrie kjøretøy. Dette vil ha en positiv effekt på andelen utslippsfrie nyregistrerte personbiler tilsvarende en økning på ca 25% i 2025.",
        unit: "",
        checked: false,
        variables: {
          ntpGoal: { values: [25] },
          vekstAvTellinger: { values: [-10] },
        },
        scale: "local",
      },
      nullLavutslippsone: {
        label: "Null- eller lavutslippsone",
        info:
          "Med dette tiltaket på (grønn bakgrunn på knappen) vil man vurdere effekten på nybilsalg dersom det innføres nulleller lavutslippssone i bysentrene. Dette vil ha en positiv effekt tilsvarende ca 10% økning i andelen utslippsfrie nyregistrerte personbiler i 2025. I tillegg forventes en reduksjon i bilparken på opp mot 1% årlig.",
        unit: "",
        checked: false,
        variables: {
          ntpGoal: { values: [10] },
          growthRate: { values: [-0.5] },
          vekstAvTellinger: { values: [-10] },
        },
        scale: "local",
      },
      arealPolitikk: {
        label: "Arealpolitikk",
        info:
          "Med dette tiltaket på (grønn bakgrunn på knappen) vil man vurdere effekten av arealpolitikk på kjøretøyparken der det antas at en arealpolitikk som legger til rette for redusert bilbruk kan gi en reduksjon i personbilparken på inntil 1% årlig.",
        unit: "",
        checked: false,
        variables: {
          growthRate: { values: [-0.5] },
          vekstAvTellinger: { values: [-5] },
        },
        scale: "local",
      },
      veibruksavgiftFor0Utslipp: {
        label: "Veibruksavgift for nullutslipp",
        info:
          "Med dette tiltaket på (grønn bakgrunn på knappen) vil man vurdere effekten på nybilsalg dersom det innføres veibruksavgift for nullutslippskjøretøy. Dette vil ha en negativ effekt tilsvarende ca 10% reduksjon i andelen utslippsfrie nyregistrerte personbiler i 2025.",
        unit: "",
        checked: false,
        variables: {
          ntpGoal: { values: [-10] },
        },
        scale: "national",
      },
    },
    values: {
      ntpGoal: {
        label: "Andel utslippsfrie biler i 2030",
        basis: 80,
        calculation: "percent",
      },
      growthRate: {
        label: "Vekst bilpark",
        basis: 0,
        calculation: "sum",
      },
      vekstAvTellinger: {
        label: "Vekst trafikktellinger",
        basis: 0,
        calculation: "sum",
      },
      percentElRenewable: {
        label: "Andel El av utslippsfrie biler",
        basis: 100,
      },
      percentBensinOfFossile: {
        label: "Andel bensin av fossile biler",
        basis: 50,
      },
    },
  },
  varebiler: {
    label: "Varebiler",
    info:
      "Det antas som basis at man oppnår ca 50% andel utslippsfrie kjøretøy av nyregistrerte kjøretøy i 2030 med dagens virkemidler, og at elbiler vil være dominerende teknologi av disse.",
    policies: {
      co2Avgift65ProsentØkning: {
        label: "Øke CO₂-avgift med 65%",
        info:
          "Med dette tiltaket på (grønn bakgrunn på knappen) vil man vurdere effekten av at CO2 avgiften økes som planlagt tilsvarende 65% over perioden. Dette vil ha en positiv effekt på innfasing av utslippsfrie varebiler, tilsvarende ca 5% økning i andelen utslippsfrie nyregistrerte varebiler i 2030.",
        unit: "",
        checked: false,
        variables: {
          ntpGoal: { values: [5] },
          m2025: { values: [5] },
        },
        scale: "national",
      },
      subsidier: {
        label: "Subsidier",
        info:
          "Dette er en vurdering av subsidier som virkemiddel. I nøytral posisjon forventes at dagens subsidier beholdes. I negativ posisjon antas en halvering av nåværende subsidier noe som vil redusere andelen utslippsfrie nyregistrerte varebiler i 2030 med ca 50%. I positiv posisjon antas en 50% økning i dagens subsidier noe som vil øke andelen utslippsfrie nyregistrerte varebiler med ca 25% i 2030.",
        unit: "",
        checked: false,
        variables: {
          ntpGoal: { values: [0, -50, 25] },
          m2025: { values: [0, -50, 25] },
        },
        scale: "national",
      },
      teknologi: {
        label: "Teknologi",
        info:
          "Dette er en vurdering av fremtidsoptimisme for utvikling av rekkevidde og pris for fossilfrie biler. I nøytral posisjon forventes en utvikling på linje med den nåværende utviklingen. I negativ posisjon forventes en stagnasjon i utviklingen noe som vil redusere andelen utslippsfrie nyregistrerte varebiler i 2030 med ca 10%. I positiv posisjon forventes en betydelig økt utviklingshastighet (for eksempel dobling av rekkevidde til samme kostnad i løpet av få år) noe som vil øke andelen utslippsfrie nyregistrerte varebiler med ca 20% i 2030.",
        unit: "",
        checked: false,
        variables: {
          ntpGoal: { values: [0, -10, 20] },
          m2025: { values: [0, -10, 20] },
        },
        scale: "national",
      },
      nullLavutslippsone: {
        label: "Null- eller lavutslippsone",
        info:
          "Med dette tiltaket på (grønn bakgrunn på knappen) vil man vurdere effekten på nybilsalg dersom det innføres nulleller lavutslippssone i bysentrene. Dette vil ha en positiv effekt tilsvarende ca 50% økning i andelen utslippsfrie nyregistrerte varebiler i 2030.",
        unit: "",
        checked: false,
        variables: {
          ntpGoal: { values: [50] },
          m2025: { values: [50] },
        },
        scale: "local",
      },
      offentligeLadeplasser: {
        label: "Offentlige dedikerte lynladeplasser",
        info:
          "Med dette tiltaket på (grønn bakgrunn på knappen) vil man vurdere effekten av offentlige dedikerte lynladeplasser for nyttekjøretøy. Dette vil ha en positiv effekt tilsvarende ca 5% økning i andelen utslippsfrie nyregistrerte varebiler i 2030.",
        unit: "",
        checked: false,
        variables: {
          ntpGoal: { values: [5] },
          m2025: { values: [5] },
        },
        scale: "local",
      },
      kravFraVareeier: {
        label: "Krav fra vareeier",
        info:
          "Med dette tiltaket på (grønn bakgrunn på knappen) vil man vurdere effekten av at vareeiere stiller krav om bruk av utslippsfrie kjøretøy. Dette vil ha en positiv effekt tilsvarende ca 20% økning i andelen utslippsfrie nyregistrerte varebiler i 2030.",
        unit: "",
        checked: false,
        variables: {
          ntpGoal: { values: [20] },
          m2025: { values: [20] },
        },
        scale: "local",
      },
      veibruksavgiftFor0Utslipp: {
        label: "Veibruksavgift for nullutslipp",
        info:
          "Med dette tiltaket på (grønn bakgrunn på knappen) vil man vurdere effekten på nybilsalg dersom det innføres veibruksavgift for nullutslippskjøretøy. Dette vil ha en negativ effekt tilsvarende ca 50% reduksjon i andelen utslippsfrie nyregistrerte varebiler i 2030.",
        unit: "",
        checked: false,
        variables: {
          ntpGoal: { values: [-50] },
        },
        scale: "national",
      },
    },
    values: {
      ntpGoal: {
        label: "Andel utslippsfrie biler 2030",
        basis: 50,
        calculation: "percent",
      },
      m2025: {
        label: "Andel utslippsfrie biler 2025",
        basis: 15,
        calculation: "percent",
      },
      growthRate: { label: "Vekst kjøretøypark", basis: 0 },
      vekstAvTellinger: { label: "Vekst trafikktellinger", basis: 0 },
      percentElRenewable: { label: "Andel El av utslippsfrie biler", basis: 100 },
      percentBensinOfFossile: { label: "Andel bensin av fossile biler", basis: 0 },
    },
  },
  letteLastebiler: {
    info:
      "Det antas som basis at man oppnår ca 25% andel utslippsfrie kjøretøy av nyregistrerte kjøretøy i 2030 med dagens virkemidler, og at elbiler vil utgjøre 75% og biogass biler utgjøre 25% av disse.",
    label: "Lastebiler",
    policies: {
      co2Avgift65ProsentØkning: {
        label: "Øke CO₂-avgift med 65%",
        info:
          "Med dette tiltaket på (grønn bakgrunn på knappen) vil man vurdere effekten av at CO2 avgiften økes som planlagt tilsvarende 65% over perioden. Dette vil ha en positiv effekt på innfasing av utslippsfrie lettere lastebiler, tilsvarende ca 5% økning i andelen utslippsfrie nyregistrerte lettere lastebiler i 2030.",
        unit: "",
        checked: false,
        variables: {
          ntpGoal: { values: [5] },
          m2025: { values: [5] },
        },
        scale: "national",
      },
      subsidier: {
        label: "Subsidier",
        info:
          "Dette er en vurdering av subsidier som virkemiddel. I nøytral posisjon forventes at dagens subsidier beholdes. I negativ posisjon antas en halvering av nåværende subsidier noe som vil redusere andelen utslippsfrie nyregistrerte lettere lastebiler i 2030 med ca 50%. I positiv posisjon antas en 50% økning i dagens subsidier noe som vil øke andelen utslippsfrie nyregistrerte lettere lastebiler med ca 25% i 2030.",
        unit: "",
        checked: false,
        variables: {
          ntpGoal: { values: [0, -50, 25] },
          m2025: { values: [0, -50, 25] },
        },
        scale: "national",
      },
      teknologi: {
        label: "Teknologi",
        info:
          "Dette er en vurdering av fremtidsoptimisme for utvikling av rekkevidde og pris for fossilfrie biler. I nøytral posisjon forventes en utvikling på linje med den nåværende utviklingen. I negativ posisjon forventes en stagnasjon i utviklingen noe som vil redusere andelen utslippsfrie nyregistrerte lettere lastebiler i 2030 med ca 20%. I positiv posisjon forventes en betydelig økt utviklingshastighet (for eksempel dobling av rekkevidde til samme kostnad i løpet av få år) noe som vil øke andelen utslippsfrie nyregistrerte lettere lastebiler med ca 50% i 2030.",
        unit: "",
        checked: false,
        variables: {
          ntpGoal: { values: [0, -20, 50] },
          m2025: { values: [0, -20, 50] },
        },
        scale: "national",
      },
      nullLavutslippsone: {
        label: "Null- eller lavutslippsone",
        info:
          "Med dette tiltaket på (grønn bakgrunn på knappen) vil man vurdere effekten på nybilsalg dersom det innføres nulleller lavutslippssone i bysentrene. Dette vil ha en positiv effekt tilsvarende ca 50% økning i andelen utslippsfrie nyregistrerte lettere lastebiler i 2030.",
        unit: "",
        checked: false,
        variables: {
          m2025: { values: [50] },
        },
        scale: "local",
      },
      offentligeDedikerteLynladeplasser: {
        label: "Offentlige dedikerte lynladeplasser",
        info:
          "Med dette tiltaket på (grønn bakgrunn på knappen) vil man vurdere effekten av offentlige dedikerte lynladeplasser for nyttekjøretøy. Dette vil ha en positiv effekt tilsvarende ca 50% økning i andelen utslippsfrie nyregistrerte lettere lastebiler i 2030.",
        unit: "",
        checked: false,
        variables: {
          m2025: { values: [50] },
          percentElRenewable: { values: [10] },
        },
        scale: "local",
      },
      energistasjonerBiogass: {
        label: "Energistasjoner for biogass",
        info:
          "Med dette tiltaket på (grønn bakgrunn på knappen) vil man vurdere effekten av utbredt og tilgjengelig infrastruktur for biogass fyllestasjoner. Dette vil antas å ha en positiv effekt tilsvarende ca 50% økning i andelen utslippsfrie nyregistrerte lettere lastebiler i 2030.",
        unit: "",
        checked: false,
        variables: {
          ntpGoal: { values: [50] },
          m2025: { values: [50] },
          percentElRenewable: { values: [-20] },
        },
        scale: "local",
      },
      kravFraVareeier: {
        label: "Krav fra vareeier",
        info:
          "Med dette tiltaket på (grønn bakgrunn på knappen) vil man vurdere effekten av at vareeiere stiller krav om bruk av utslippsfrie kjøretøy. Dette vil ha en positiv effekt tilsvarende ca 20% økning i andelen utslippsfrie nyregistrerte lettere lastebiler i 2030.",
        unit: "",
        checked: false,
        variables: {
          ntpGoal: { values: [20] },
          m2025: { values: [20] },
        },
        scale: "local",
      },
    },
    values: {
      ntpGoal: {
        label: "Andel utslippsfrie kjøretøy 2030",
        basis: 50,
        calculation: "percent",
      },
      m2025: {
        label: "Andel utslippsfrie kjøretøy 2025",
        basis: 20,
        calculation: "percent",
      },
      growthRate: { label: "Vekst bilpark", basis: -0.5 },
      vekstAvTellinger: { label: "Vekst trafikktellinger", basis: 0 },
      percentElRenewable: {
        label: "Andel El av utslippsfrie biler",
        basis: 75,
        calculation: "percent",
      },
      percentBensinOfFossile: { label: "Andel bensin av fossile biler", basis: 0 },
    },
  },
  tyngreLastebiler: {
    label: "Vogntog",
    info:
      "Det antas som basis at man oppnår ca 10% andel utslippsfrie kjøretøy av nyregistrerte kjøretøy i 2030 med dagens virkemidler, og at biogassbiler og hydrogenbiler vil være dominerende, biogass først før hydrogen vokser mot slutten av 2020 tallet.",
    policies: {
      co2Avgift65ProsentØkning: {
        label: "Øke CO₂-avgift med 65%",
        info:
          "65% økning i CO2 avgift: Med dette tiltaket på (grønn bakgrunn på knappen) vil man vurdere effekten av at CO2 avgiften økes som planlagt tilsvarende 65% over perioden. Dette vil ha en positiv effekt på innfasing av utslippsfrie tyngre lastebiler, tilsvarende ca 5% økning i andelen utslippsfrie nyregistrerte tyngre lastebiler i 2030.",
        unit: "",
        checked: false,
        variables: {
          ntpGoal: { values: [5] },
          m2025: { values: [5] },
        },
        scale: "national",
      },
      subsidier: {
        label: "Subsidier",
        info:
          "Dette er en vurdering av subsidier som virkemiddel. I nøytral posisjon forventes at dagens subsidier beholdes. I negativ posisjon antas en halvering av nåværende subsidier noe som vil redusere andelen utslippsfrie nyregistrerte tyngre lastebiler i 2030 med ca 50%. I positiv posisjon antas en 50% økning i dagens subsidier noe som vil øke andelen utslippsfrie nyregistrerte tyngre lastebiler med ca 25% i 2030.",
        unit: "",
        checked: false,
        variables: {
          ntpGoal: { values: [0, -50, 25] },
          m2025: { values: [0, -50, 25] },
        },
        scale: "national",
      },
      teknologi: {
        label: "Teknologi",
        info:
          "Dette er en vurdering av fremtidsoptimisme for utvikling av rekkevidde og pris for fossilfrie biler. I nøytral posisjon forventes en utvikling på linje med den nåværende utviklingen. I negativ posisjon forventes en stagnasjon i utviklingen noe som vil redusere andelen utslippsfrie nyregistrerte tyngre lastebiler i 2030 med ca 20%. I positiv posisjon forventes en betydelig økt utviklingshastighet (for eksempel dobling av rekkevidde til samme kostnad i løpet av få år) noe som vil øke andelen utslippsfrie nyregistrerte tyngre lastebiler med ca 50% i 2030.",
        unit: "",
        checked: false,
        variables: {
          ntpGoal: { values: [0, -20, 50] },
          m2025: { values: [0, -20, 50] },
        },
        scale: "national",
      },
      nullLavutslippsone: {
        label: "Null- eller lavutslippsone",
        info:
          "Med dette tiltaket på (grønn bakgrunn på knappen) vil man vurdere effekten på nybilsalg dersom det innføres nulleller lavutslippssone i bysentrene. Dette vil ha en positiv effekt tilsvarende ca 50% økning i andelen utslippsfrie nyregistrerte tyngre lastebiler i 2030.",
        unit: "",
        checked: false,
        variables: {
          ntpGoal: { values: [50] },
          m2025: { values: [50] },
        },
        scale: "local",
      },
      energistasjonerH2Biogass: {
        label: "Energistasjoner for H₂⁺ og biogass",
        info:
          "Med dette tiltaket på (grønn bakgrunn på knappen) vil man vurdere effekten av utbredt og tilgjengelig infrastruktur for biogass og hydrogen fyllestasjoner. Dette vil antas å ha en positiv effekt tilsvarende ca 50% økning i andelen utslippsfrie nyregistrerte tyngre lastebiler i 2030.",
        unit: "",
        checked: false,
        variables: {
          ntpGoal: { values: [50] },
          m2025: { values: [50] },
        },
        scale: "local",
      },
      kravFraVareeier: {
        label: "Krav fra vareeier",
        info:
          "Med dette tiltaket på (grønn bakgrunn på knappen) vil man vurdere effekten av at vareeiere stiller krav om bruk av utslippsfrie kjøretøy. Dette vil ha en positiv effekt tilsvarende ca 20% økning i andelen utslippsfrie nyregistrerte tyngre lastebiler i 2030.",
        unit: "",
        checked: false,
        variables: {
          ntpGoal: { values: [20] },
          m2025: { values: [20] },
        },
        scale: "local",
      },
    },
    values: {
      ntpGoal: {
        label: "Andel utslippsfrie kjøretøy 2030",
        basis: 20,
        calculation: "percent",
      },
      m2025: {
        label: "Andel utslippsfrie kjøretøy 2025",
        basis: 5,
        calculation: "percent",
      },
      growthRate: { label: "Vekst bilpark", basis: -0.5 },
      vekstAvTellinger: { label: "Vekst trafikktellinger", basis: 0 },
      percentElRenewable: { label: "Andel Hydrogen av utslippsfrie kjøretøy", basis: 50 },
      percentBensinOfFossile: { label: "Andel bensin av fossile kjøretøy", basis: 0 },
    },
  },
};
