export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isZeros(arr) {
  const sorted = arr;
  sorted.sort();
  const allEqual = sorted[0] === sorted[sorted.length - 1];
  const allZero = [0].includes(sorted[0]);
  return allEqual && allZero;
}
