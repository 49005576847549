import Info from "components/Info";

export default ({ header, policy }) => {
  const firstVariable = policy.variables[Object.keys(policy.variables)[0]];

  const checkMessage =
    "Av- og på-bryter (" + (policy.checked ? "på" : "av") + " som standard)";

  const optionsLength = firstVariable && firstVariable.values.length;
  const optionsDefault = firstVariable && firstVariable.values[0];
  let optionDefaultString = () => {
    if (optionsDefault === 0) {
      return "nøytral";
    } else if (optionsDefault > 0) {
      return "positiv";
    } else if (optionsDefault < 0) {
      return "negativ";
    }
  };
  const optionsMessage =
    optionDefaultString() && optionsLength > 1
      ? "Har " +
        optionsLength +
        " alternativer (" +
        optionDefaultString() +
        " som standard)"
      : null;

  const stringCandidates = [checkMessage, optionsMessage, policy.info];
  let strings = [];

  stringCandidates.forEach((string) => {
    if (!!string) {
      strings.push(string);
    }
  });

  const body = strings.join(". ");

  return <Info header={header}>{body}</Info>;
};
